<template>
  <div style="text-align: left">
    <div class="pc">
      <div class="h7 main">결제 내역</div>
      <div class="divider"></div>
      <div class="accounts">
        <div v-for="(item,idx) in list" :key="'account-item-'+idx" class="card-account">
          <div class="flex-between">
            <div style="margin-bottom:4px"><span class="h8 sub2">{{ item.name }}</span><span class="body4 sub4" style="margin-left:4px">({{ datesFormat(item.created_time, 'date_3') }})</span></div>
            <div class="body2 sub2"><span class="price h8 main">{{ item.price|currencyNum }}</span>원</div>
          </div>
          <div class="body2 sub3">{{ code[item.code] }} {{ item.num }}</div>
        </div>
      </div>

      <button class="button is-primary" style="margin-top:16px;width:100%" @click="$emit('close')">확인</button>
    </div>
    <div class="mobile">
      <div class="header-mobile">
        <div class="header-wrapper">
          <div class="h8 main">결제정보</div>
          <img src="/static/icon/fi_close.svg" class="svg-sub4" @click="$emit('close')">
        </div>
      </div>
      <div class="accounts">
        <div v-for="(item,idx) in list" :key="'account-item-'+idx" class="card-account">
          <div class="flex" style="justify-content: space-between">
            <div>
              <div>{{ item.name }}</div>
              <div class="body5 sub4">{{ datesFormat(item.created_time, 'date_3') }}</div>
            </div>
            <div class="body2 sub2"><span class="price h8 main">{{ item.price|currencyNum }}</span>원</div>
          </div>
          <div class="body5 sub3">{{ code[item.code] }} {{ item.num }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "MyAccountList",
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        code: {
          '01': '하나(외환)카드',
          '03': '롯데카드',
          '04': '현대카드',
          '06': '국민카드',
          '11': 'BC카드',
          '12': '삼성카드',
          '14': '신한카드',
          '16': '농협카드',
          '21': '해외 VISA카드',
          '22': '해외마스터카드',
          '23': '해외 JCB카드',
          '26': '중국은련',
          '32': '광주은행',
          '33': '전북은행',
          '34': '하나은행',
          '35': '산업카드',
          '41': 'NH',
          '43': '씨티은행',
          '44': '우리은행',
          '48': '신협체크',
          '51': '수협',
          '52': '제주은행',
          '54': 'MG새마을금고체크',
          '55': '케이뱅크',
          '56': '카카오뱅크',
          '71': '우체국체크',
          '95': '저축은행체크'
        },
        list: [
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },
          {
            title: '최초 개설비용',
            created_time: '2021-06-08',
            price: 5500000,
            card: '현대카드 6290****0000****'
          },

        ]
      }
    },
    methods: {
      getData() {
        this.request.user.get(`launchpack/service/${this.$route.query.id}/bill_history`).then(res=>{
          this.list = res.data;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .divider
    width 100%
    height 1px
    background-color $sub5
    margin 16px 0
  .card-account
    border-bottom 1px solid $gray2
    padding 16px 0
  .card-account:first-child
    padding-top 0

  .header-mobile
    position fixed
    top 0
    left 0
    width 100%
    padding 0 20px
    background-color white
  .header-wrapper
    padding 24px 0 20px 0
    display flex
    justify-content space-between
    border-bottom 1px solid $sub5

  @media (min-width:501px)
    .accounts
      overflow-y auto
      max-height 50vh
      padding-right 20px

  @media (max-width:1024px)
    .accounts
      padding 70px 20px
    .card-account:first-child
      padding-top 16px
</style>
